import React, { useState, useEffect } from "react"
import { buildClient } from "@datocms/cma-client-browser"
import {
  Container,
  Flex,
  Heading,
  Input,
  Link,
  Text,
  Box,
} from "@theme-ui/components"
import ReactPaginate from "react-paginate"
import { useSiteSearch } from "react-datocms"
import { i18nContext, languages } from "../context/i18nContext"
import { LocaleContext } from "../context/localeContext"
import Layout from "../components/layout"
const client = buildClient({ apiToken: "8eec0564daac867ce3be053e738a20" })

function Search({ pageContext, location }) {
  const [query, setQuery] = useState("")
  const { locale } = pageContext
  const { state, error, data } = useSiteSearch({
    client,
    initialState: { locale: locale },
    buildTriggerId: "13573",
    resultsPerPage: 20,
  })

  const updateUrlParam = searchQuery => {
    const params = new URLSearchParams(location.search)
    if (searchQuery) {
      params.set("s", searchQuery)
    } else {
      params.delete("s")
    }
    // Update URL without page reload
    window.history.replaceState(
      {},
      "",
      `${location.pathname}?${params.toString()}`
    )
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const searchQuery = params.get("s")
    if (searchQuery) {
      setQuery(searchQuery)
      state.setQuery(searchQuery)
    }
  }, [location.search])

  return (
    <LocaleContext.Provider
      value={{
        activeLocale: locale,
        paths: [
          {
            locale: "en",
            value: "en/search",
          },
          {
            locale: "it",
            value: "ricerca",
          },
        ],
      }}
    >
      <i18nContext.Provider value={languages[locale]}>
        <Layout locale={locale}>
          <i18nContext.Consumer>
            {t => (
              <Container>
                <Heading as="h1" sx={{ mb: [3] }}>
                  {t.search}
                </Heading>
                <Box
                  as="form"
                  onSubmit={e => {
                    e.preventDefault()
                    state.setQuery(query)
                    updateUrlParam(query)
                  }}
                  sx={{ mb: [4] }}
                >
                  <Input
                    type="search"
                    value={query}
                    placeholder={t.searchPlaceholder}
                    onChange={e => setQuery(e.target.value)}
                  />
                </Box>
                {!data && !error && <p>{t.loading}</p>}
                {error && (
                  <p>
                    {t.error} {error}
                  </p>
                )}
                {data && (
                  <>
                    {data.pageResults.map(result => (
                      <Link
                        key={result.id}
                        href={result.url}
                        sx={{
                          display: "block",
                          textDecoration: "none",
                          border: "1px solid #ddd",
                          p: 3,
                          mb: [3],
                          transition: "all 0.3s ease",
                          "&:hover": {
                            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                          },
                          mark: {
                            backgroundColor: "#eeeeee",
                            color: "secondary",
                            fontWeight: "bold",
                          },
                        }}
                      >
                        <Flex sx={{ flexDirection: "column" }}>
                          <Text
                            as="h2"
                            sx={{
                              fontWeight: "bold",
                              color: "primary",
                              mb: [1],
                              fontSize: [2, 3],
                            }}
                          >
                            {result.title}
                          </Text>
                          <Text sx={{ fontSize: [2] }}>
                            ... {result.bodyExcerpt} ...
                          </Text>
                          <Text sx={{ fontSize: "13px", color: "gray", mb: 0 }}>
                            {result.url}
                          </Text>
                        </Flex>
                      </Link>
                    ))}
                    {query && (
                      <p>
                        {t.totalResults}: {data.totalResults}
                      </p>
                    )}
                    <Flex
                      sx={{
                        justifyContent: "center",
                        ul: {
                          listStyle: "none",
                          paddingLeft: 0,
                          li: {
                            display: "inline-block",
                            cursor: "pointer",
                            mx: [1],
                            a: {
                              color: "secondary",
                              "&:hover": {
                                color: "primary",
                              },
                            },
                            "&.active a": {
                              color: "primary",
                              fontWeight: "bold",
                            },
                            "&.disabled": {
                              opacity: 0.5,
                            },
                          },
                        },
                      }}
                    >
                      <ReactPaginate
                        pageCount={data.totalPages}
                        forcePage={state.page}
                        onPageChange={({ selected }) => {
                          state.setPage(selected)
                          window.scrollTo({ top: 0, behavior: "smooth" })
                        }}
                        activeClassName="active"
                        renderOnZeroPageCount={() => null}
                        previousLabel={t.previous}
                        nextLabel={t.next}
                      />
                    </Flex>
                  </>
                )}
              </Container>
            )}
          </i18nContext.Consumer>
        </Layout>
      </i18nContext.Provider>
    </LocaleContext.Provider>
  )
}

export default Search
